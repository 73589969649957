import * as $ from 'jquery';
import { TabsAccordionConfig } from "./Configuration/TabsAccordionConfig";

export class TabsAccordions {
    private Config: TabsAccordionConfig;
    private WindowSize: number;
    private IsMobileSize: boolean;
    private readonly HashString: string;
    constructor(Configuration: TabsAccordionConfig) {
        this.Config = Configuration;
        this.WindowSize = 0;
        this.IsMobileSize = false;
        this.HashString = window.location.hash;
    }

    public Setup() {
        this.TabsFromCore(this.HashString);
        this.UpdateWindowSize();
        let accordionCount = 0;
        $('.accordion').each((i, el) => {
            $(el).hasClass('small-only') || $(el).hasClass('tabs') || $(el).hasClass('always') ? '' : $(el).addClass('always');
            $(el).find('> .toggle').each((j, elem) => {
                accordionCount++;
                $(elem).html(`<button>${$(elem).html()}</button>`);
                let content = $(elem).next('.content');
                let button = $(elem).find('button');
                if ($(el).is('.tabs') && !($(el).is('.mobile-accordion') && this.IsMobileSize)) {
                    // Tabs
                    this.AddTabAttributes(button, content, accordionCount);
                } else {
                    // Accordions
                    this.AddAccordionAttributes(button, content, accordionCount);
                }
                $(content).hide().wrapInner(`<div class="padding"></div>`);
            });
        });

        this.AccordionTabs();
        this.AccordionSmall();
        this.AccordionOpen();

        let timer = undefined;
        window.onresize = () => {
            if(timer) {
                clearTimeout(timer);
            }
            // We set a small delay to wait for the user to stop resizing the window
            timer = setTimeout(() => {
                this.UpdateWindowSize();
                this.AccordionTabs();
                this.AccordionSmall();
                this.AccordionOpen();
                this.RegisterEventHandlers();
            }, 200);
        };

        this.RegisterEventHandlers();
    }

    public AddSizeClasses(el) {
        $(el).removeClass('mobile desktop');
        if (this.IsMobileSize) {
            $(el).addClass('mobile');
        } else {
            $(el).addClass('desktop');
        }
    }

    // Add size classes to each accordion for styling purposes
    public UpdateWindowSize() {
        this.WindowSize = Math.max((window as any).innerWidth, window.innerWidth);
        this.IsMobileSize = this.WindowSize < this.Config.Breakpoint;

        $('.accordion').each((i, el) => {
            this.AddSizeClasses(el);
        });
    }

    // Adding a class of 'open' on an accordion or tab allows certain sections of the content to open automatically on page load
    public AccordionOpen() {
        let activeTab = '';

        // We have an accordion
        $('.accordion > .toggle.open').each((i, el) => {
            activeTab = $(el).find('button').attr('id');

            if(activeTab) {
                $(el).find('button').attr('aria-expanded', 'true');
                $('[aria-labelledby="' + activeTab + '"]').removeAttr('style').show();
            }
        });

        // We have a tab
        $('.accordion > .tablist button.toggle.open').each((i, el) => {
            if($(el).attr('id')) {
                activeTab = $(el).attr('id');

                if(activeTab) {
                    // The first tab is displayed by default
                    $(el).siblings('button:first').attr({
                        'aria-selected': false,
                        'tabindex': '-1'
                    });
                    // Hide the first tab content
                    $(el).parent('.tablist').parent('.accordion').find('> .content:first').removeAttr('style').css('display', 'none');
                    // Show the tab with the class of 'open'
                    $(el).attr('aria-selected', 'true').removeAttr('tabindex');
                    $('[aria-labelledby="' + activeTab + '"]').removeAttr('style').css('display', 'block');
                }
            }
        });
    }

    // Adding a class of 'small-only' has plain text converted to an accordion in mobile view
    public AccordionSmall() {
        $('.accordion.small-only').each((i, el) => {
            if (this.IsMobileSize) {
                $(el).find('> .toggle').each((j, elem) => {
                    let content = $(el).find('> .content').eq(j);
                    let index = $(content).attr('id').split('-');

                    // Add a button in for mobile. This happens when we go from desktop to mobile
                    if($(elem).has('button').length === 0) {
                        $(elem).contents().wrap(`<button aria-expanded="false" aria-controls="accordion-${index[1]}" id="accordion-${index[1]}-toggle"></button>`);
                    }
                    $(content).attr({
                        'aria-labelledby': `accordion-${index[1]}-toggle`,
                        'role': 'region'
                    }).hide();
                });
            } else {
                // Remove the button
                $(el).find('> .toggle button').contents().unwrap();
                $(el).find('> .content').show().removeAttr('style aria-labelledby role');
            }
        });
    }

    public AccordionTabs() {
        let tabs = '.accordion.tabs';
        let lgDevice = this.WindowSize >= this.Config.Breakpoint;
        let smDevice = !lgDevice;

        $(tabs).each((i, el) => {
            let content = $(el).find('> .content');
            let index = 0;

            if ((lgDevice) || (smDevice && !$(el).is('.mobile-accordion'))) {
                // Set up tabs
                if ($(el).find('> .tablist').length === 0) {
                    $($(el).find('> .toggle > button')).each((j, button) => {
                        // Change attributes when moving from accordion to tabs
                        if(!$(button)[0].hasAttribute('aria-selected')) {
                            index = $(button).attr('id').match(/\d+/);
                            this.AddTabAttributes(button, content[j], index[0]);
                        }
                    });

                    // Build out the tablist and add the buttons to it
                    $(el).prepend('<div class="tablist" role="tablist"></div>');
                    // Find each header tag, they now have a button inside. Add each button to the Tablist, then remove the header tag
                    $(el).find('> .toggle').each((i, elem) => {
                        if(i === 0) {
                            $(elem).find('> button').attr({
                                'aria-selected': 'true',
                                /* The accordion markup has a header tag, the tabs do not. Store the header tag here
                                   before we blow it away in case we need to build an accordion later */
                                'original-tag-name': $(elem).prop('tagName')
                            }).removeAttr('tabindex');
                        }
                        if($(elem).hasClass('open')) {
                            $(elem).find('button').addClass('open');
                        }
                        let toggle = $(elem).html();
                        $(toggle).appendTo($(elem).closest(tabs).find('> .tablist'));
                    }).remove();

                    $(el).find('> .content:first').css('display', 'block').show();
                }
            } else if(smDevice && $(el).is('.mobile-accordion')) {

                // Coming from large screen to small. In tab format, convert to accordion
                if ($(el).find('> .tablist').length) {
                    let tagName = '';
                    let classNames = 'toggle';
                    $(el).find('> .tablist > button').each((i, elem) => {
                        if(i === 0) {
                            // We stored the header tag name when we made it into Tabs
                            tagName = $(elem).prop('original-tag-name') || 'h3';
                        }
                        if($(elem).hasClass('open')) {
                            classNames += ' open';
                        }
                        if(!$(elem)[0].hasAttribute('aria-expanded')) {
                            // Change attributes on buttons and contents
                            index = $(elem).attr('id').match(/\d+/);
                            this.AddAccordionAttributes(elem, content[i], index[0]);
                        }
                        // Take the buttons out of the Tablist and restructure them for the accordion
                        let button = $(elem)[0].outerHTML;
                        $(el).find('> .content:eq('+ i +')').before(`<${tagName} class='${classNames}'>${button}</${tagName}>`);
                        classNames = 'toggle';
                    });
                    $(el).find('> .tablist').remove();
                }
            }
        });
    }

    public KeyboardFunctionality(e, tabs) {
        let el = e.currentTarget;
        switch(e.code || e.key) {
            case 'Enter':
            case 'Space':
            case 'Spacebar':
                $(e).click();
                return false;
            case 'Up':
            case 'Left':
            case 'ArrowUp':
            case 'ArrowLeft':
                if(tabs) {
                    if($(el).is(':first-child')) {
                        $(el).siblings(":last").focus().click();
                    } else {
                        $(el).prev('button.toggle').focus().click();
                    }
                } else {
                    $(el).prevAll('button.toggle:first').focus();
                }
                e.preventDefault();
                return false;
            case 'Down':
            case 'Right':
            case 'ArrowDown':
            case 'ArrowRight':
                if(tabs) {
                    if($(el).is(':last-child')) {
                        $(el).siblings(":first").focus().click();
                    } else {
                        $(el).next('button.toggle').focus().click();
                    }
                } else {
                    $(el).nextAll('button.toggle:first').focus();
                }
                e.preventDefault();
                return false;
        }
    }

    public AddTabAttributes(button, content, index) {
        $(button).removeAttr('aria-expanded aria-controls id').attr({
            'class': 'toggle',
            'aria-selected': false,
            'aria-controls': `accordion-tab-${index}`,
            'id': `accordion-${index}`,
            'role': 'tab',
            'tabindex': '-1'
        });

        $(content).removeAttr('class id aria-labelledby role').attr({
            'tabindex': '0',
            'class': 'content',
            'id': `accordion-tab-${index}`,
            'aria-labelledby': `accordion-${index}`,
            'role': 'tabpanel'
        }).css('display', 'none');
    }

    public AddAccordionAttributes(button, content, index) {
        $(button).removeAttr('class aria-selected aria-controls id role tabindex').attr({
            'aria-expanded': false,
            'aria-controls': `accordion-${index}`,
            'id': `accordion-${index}-toggle`,
        });

        $(content).removeAttr('tabindex class id aria-labelledby role').attr({
            'class': 'content',
            'id': `accordion-${index}`,
            'aria-labelledby': `accordion-${index}-toggle`,
            'role': 'region'
        }).css('display', 'none');
    }

    public RegisterEventHandlers() {
        $(document).off().on('click', '.accordion.tabs > .tablist > button.toggle', this.TabClick);
        $(document).on('keydown', '.accordion.tabs > .tablist > button.toggle', (el) => {
            this.KeyboardFunctionality(el, true);
        });

        $('.accordion').not('.multi').find('.toggle button').off().on('click', (el) => {
            this.AccordionClick(el, this);
            return false;
        });

        // The second selector is needed to catch a nested multi accordion
        $('.accordion.multi .toggle button, .accordion .multi .toggle button').off().on('click', (el) => {
            let elem = el.currentTarget;
            let activeTab = $(elem).attr('id');

            if ($(elem).attr('aria-expanded') === 'true') {
                $(elem).attr('aria-expanded', 'false');
            } else {
                $(elem).attr('aria-expanded', 'true');
            }
            $('[aria-labelledby="' + activeTab + '"]').slideToggle(this.Config.Animation);
            return false;
        });
    }

    public AccordionClick(el, self) {
        let elem = el.currentTarget;
        if($(elem).closest('.accordion').is('.small-only.desktop') || $(elem).closest('.accordion').is('.multi')) {
            return false;
        }
        let activeTab = $(elem).attr('id');
        if ($(elem).attr('aria-expanded') === 'true') {
            // Hide container
            $(elem).attr('aria-expanded', 'false');
            $('[aria-labelledby="' + activeTab + '"]').slideUp(self.Config.Animation);
            return false;
        } else {
            // Show container
            // Find out if one of the accordions above where is clicked is expanded
            let isExpanded = false;
            $(elem).parents('.toggle').prevAll('.toggle').find('button').each((i, el) => {
                $(el).attr('aria-expanded') === 'true' ? isExpanded = true : '';
            });
            $(elem).closest('.accordion').find('> .toggle button').attr('aria-expanded', 'false');
            $(elem).closest('.accordion').find('> .content').slideUp(self.Config.Animation);
            $(elem).attr('aria-expanded', 'true');
            // Find out if there are accordions above where the user clicked
            if(($(elem).parents('.toggle').prev().length)) {
                if(isExpanded) {
                    // Find the first accordion in the group
                    let firstElem = $(elem).parents('.toggle').siblings('.toggle:first').find('button');
                    // Find out if that first element is scrolled above the top of the window out of view
                    let scrollTop = $(window).scrollTop()
                    let elementOffset = $(firstElem).offset().top;
                    let distance = (elementOffset - scrollTop);

                    /* The first accordion is off the top of the page, so we know it's contents are long.
                    When we click on another accordion below it, the long contents will be hidden and we will be potentially scrolled too
                    far up the page and out of view of the accordion we just clicked on. The idea here is once the long contents are hidden
                    we want to scroll the page a bit so the user will get a centered view of where they clicked. */
                    if (distance + $(firstElem).outerHeight() < 0) {
                        /* Subtract the id's from the first one to the one we clicked on so we know how many elements we have above where we clicked.
                        Take that number and multiply it by the height of the first element (assuming all of the accordion buttons are the same height)
                        Then add 1/2 of the height of the current button so we are at the center of the button we clicked on */
                        let accordionHeights = (($(elem).attr('id').split('-')[1] - $(firstElem).attr('id').split('-')[1]) * $(firstElem).outerHeight())+($(elem).outerHeight()/2);
                        /* Take the offset of the accordions from the top of the page and add it to our calculated distance from the top of the accordions
                        to our button, then subtract where the user clicked. This will position the mouse at the spot the user clicked, on that active button */
                        let offset = $(firstElem).offset().top + accordionHeights - el.clientY;

                        $('html,body').animate({
                            scrollTop: offset
                        }, self.Config.Animation);
                    }
                }
            }
            $('[aria-labelledby="' + activeTab + '"]').slideDown(self.Config.Animation);
        }
        return false;
    }

    public TabClick(el) {
        let tabs = '.accordion.tabs';
        let elem = el.currentTarget;
        if ($(elem).attr('aria-selected') === 'false') {
            let activeTab = $(elem).attr('id');
            $(elem).closest(tabs).find('> .tablist button.toggle').attr({
                'aria-selected': 'false',
                'tabindex': '-1'
            });
            $(elem).attr('aria-selected', 'true').removeAttr('tabindex');
            $(elem).closest(tabs).find('> .content').hide();
            $('[aria-labelledby="' + activeTab + '"]').css('display', 'block').show();
        }
        return false;
    }

    public TabsFromCore(hash) {
        /* If we have tabs that come from core, convert the markup to be in the format we need.
        Refer to the ReadMe file to see what the markup is coming from Core and what we turn it into. */

        // Get text from URL hash to determine tab to show.
        hash = hash.length ? hash.replace('#', '') : '';

        $('[id*="VsMasterPage"].TabContainer').each(function (i, el) {
            let tab = $(el).find('.Tab');

            $(el).addClass('accordion tabs mobile-accordion');
            $(el).find('.Tabnav').remove(); //Get rid of the ul - Don't need it

            $(tab).each(function(j, elem) {
                //Find the header element and get all the information from it to recreate later
                $(elem).find(':header:first').addClass('TabHeader');
                let tabHeader = $(elem).find('.TabHeader');
                let tagName = $(tabHeader).eq(0).prop('tagName');
                let tabName = $(tabHeader).text().trim();
                let className = 'toggle';
                $(tabHeader).remove(); // Get rid of the TabHeader. Now all we have is the content of the tab
                // On page reload, set current tab to display.
                let tabAsHash = tabName.replace(/[^a-zA-Z0-9]/g, '');
                !hash && !tabAsHash ? (j === 0) ? className += ' open' : '' // Open the first accordion by default
                    : hash === tabAsHash ? className += ' open' : ''; // Open current tab.

                // Reformat the Tab div
                $(elem).removeAttr('id').removeClass().addClass('content')
                    .before(`<${tagName} class="${className}">${tabName}</${tagName}>`);
            });
        });
    }
}

export function Create(userConfig?: TabsAccordionConfig) {
    const config = new TabsAccordionConfig();
    Object.assign(config, userConfig || {});

    return new TabsAccordions(config);
}
